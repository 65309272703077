import React from "react";

const Launch = () => {
  return (
    <>
      <p>Launch Page Under Construction</p>
    </>
  );
};

export default Launch;
