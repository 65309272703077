import React, { Fragment } from "react";

const NotFound = () => (
  <Fragment>
    <p>Page not found</p>
  </Fragment>
);

export default NotFound;

